import React, { ErrorInfo } from "react";
import { postError } from "../../service/auth";
import { ERROR_LOG_SUBTYPE, ERROR_LOG_TYPE } from "../../constant/enum";
import WarningCircleBlue from "../../Assets/Images/warningCircleBlue.svg";
import "./UnAuthorizedScreen.scss";

interface IErrorBoundaryState {
    hasError: boolean;
}
class ErrorBoundary extends React.Component<any, IErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ hasError: true });
        const errorStackJSONString = JSON.stringify({
            type: ERROR_LOG_TYPE.FRONTEND_ERROR,
            subType: ERROR_LOG_SUBTYPE.UI,
            error: error.message,
            componentStack: errorInfo
        });
        postError(errorStackJSONString);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="container-fluid">
                    <div className="warning-notes">
                        <img src={WarningCircleBlue} alt="WarningImg" />
                        <h6>Something went wrong.</h6>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
import axios from "axios";
import moment from "moment";
import { clearAll, getLocalStorageByKey, setLocalStorageByKey, LOCALSTORAGE_KEY } from "../utils/auth";
import { IDLE_TIMER_TIMEOUT } from "../constant/constant";
import { fetchRefreshToken, postError } from "./auth";
import { ERROR_LOG_SUBTYPE, ERROR_LOG_TYPE } from "../constant/enum";

export enum API_STATUS_CODE {
    UNAUTHORIZE = 401,
    FORBIDDEN = 403,
    NOT_ACCEPTABLE = 406,
    SUCCESS = 200
}

enum FORBIDDEN_SUB_STATUS_CODE {
    EXPIRED_JWT = "EXPIRED_JWT",
    MALFORMED_JWT = "MALFORMED_JWT",
    USER_NOT_FOUND = "USER_NOT_FOUND",
    UNAUTHORIZE_USER = "You are not authorized to login in to the application, kindly contact the system administrator.",
    UNAUTHORIZE_TOKEN = "failed to get access token"
}

const publicURLS = [
  "/agreement/authorizeagreement",
  "/agreement/terms-and-conditions/gettermandcondition",
  "/configuration/getconfiguration",
  "/jetcard/cardclientview",
];

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use((config: any) => {
    let isPublicURl = false;
    publicURLS.forEach((url: string) => {
        if (config.url.includes(url)) {
            isPublicURl = true;
        }
    });
    if(config.url.split('/').includes('pdf')){
        isPublicURl = false;
    }

    const token = getLocalStorageByKey(LOCALSTORAGE_KEY.TOKEN);
    if (config.headers && token && !isPublicURl) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    // To identify which api is public
    if (config.headers && config.headers.isPublic) {
        delete config.headers.Authorization;
        delete config.headers.isPublic;
    }
    // Todo: For future use, currently it's generating CORS Error
    // config.headers['x-request-id'] = shortId.generate();

    // Logout on idle Time
    const lastApiTimestamp = getLocalStorageByKey(LOCALSTORAGE_KEY.LAST_API_TIMESTAMP);
    if (lastApiTimestamp) {
        const currentTime = moment();
        const lastApiTimestampMoment = moment(lastApiTimestamp);
        if (currentTime.diff(lastApiTimestampMoment, 'seconds') > IDLE_TIMER_TIMEOUT) {
            logout();
        }
    }

    return config;
});

let isTokenRefreshing = false; // For avoiding multiple refresh token call
let requestCaching: Array<(token: string) => void> = [];
axios.interceptors.response.use(
    (response) => {
        setLocalStorageByKey(LOCALSTORAGE_KEY.LAST_API_TIMESTAMP, moment().toString());
        return Promise.resolve(response);
    },
    (error) => {
        const { config, response } = error;
        const originalRequest = config;
        if (!response || response.status === API_STATUS_CODE.FORBIDDEN) {
            const errorStackJSONString = JSON.stringify({
                type: ERROR_LOG_TYPE.FRONTEND_ERROR,
                subType: ERROR_LOG_SUBTYPE.API,
                error: error.message,
                request: {
                    url: originalRequest.url,
                    data: originalRequest.data,
                    method: originalRequest.method,
                },
                response: {
                    status: response?.status ?? null,
                    data: response?.data ?? null,
                }
            });
            postError(errorStackJSONString);
            window.location.replace("/403");
            return Promise.reject(error);
        } else if (response.status === API_STATUS_CODE.UNAUTHORIZE) {
            if (response.data.responseMessage === FORBIDDEN_SUB_STATUS_CODE.EXPIRED_JWT) {
                if (!isTokenRefreshing) {
                    isTokenRefreshing = true;
                    fetchRefreshToken((_, isError) => {
                        if (isError) {
                            logout();
                        } else {
                            setTimeout(() => {
                                recallCachedAPIs();
                                requestCaching = [];
                                isTokenRefreshing = false;
                            }, 1000); // To handle parallel API Calls
                        }
                    });
                }
                // Cache all API call and resolve only after token refreshed!
                return new Promise((resolve, reject) => {
                    subscribeToken((token: string) => {
                        originalRequest.headers.Authorization = 'Bearer ' + token;
                        resolve(axios(originalRequest));
                    });
                });
            } else if (
                response.data.responseMessage === FORBIDDEN_SUB_STATUS_CODE.UNAUTHORIZE_USER ||
                response.data.responseMessage === FORBIDDEN_SUB_STATUS_CODE.UNAUTHORIZE_TOKEN
            ) {
                return Promise.reject(error);
            } else {
                logout();
            }
        } else {
            return Promise.reject(error);
        }
    }
);

// Cached all Http calls which one failed
const subscribeToken = (cb: (token: string) => void) => {
    requestCaching.push(cb);
}

// Recall once the access token refreshed
const recallCachedAPIs = () => {
    const token = getLocalStorageByKey(LOCALSTORAGE_KEY.TOKEN);
    if (token) {
        requestCaching.forEach(cb => cb(token));
    }
}

const logout = () => {
    clearAll();
    window.location.href = `http://${window.location.host}${window.location.pathname}`
}

const useAxios = async ({ url, method, body = null, headers = null }: any) => {
  let response: any = null;
  let error = "";
  let loading = true;
  let status = null;
  const api: any = axios;
  const fetchData = async () => {
    await api[method](url, JSON.parse(body), JSON.parse(headers))
      .then((res: any) => {
        status = res.status;
        response = res.data;
      })
      .catch((err: any) => {
        response = err?.response?.data ?? null;;
      })
      .finally(() => {
        loading = false;
      });
  };

  // useEffect(() => {
  //     fetchData();
  // }, [method, url, body, headers]);
  await fetchData();

  return { response, error, loading, status };
};

export const AxiosService = () => axios;

export default useAxios;

//import React from 'react';
import React, { useState, useEffect } from "react";
import JetLogo from "../../Assets/Images/jet-logo.svg";
import "./Header.scss";
import { Button } from "@progress/kendo-react-buttons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearAll } from "../../utils/auth";
import { resetLoginUser } from "../../redux/action/auth";
import useAxios, { AxiosService } from "../../service/Service";
import Notifications from "./Notification";
import GlobalSearchInput from "./GlobalSearch";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authUser, permission } = useSelector((state: any) => state.auth);
  const [userImage, setUserImage] = React.useState<any>();

  const documentElement: any = document;
  const openSidebar = () => {
    documentElement.getElementById("mySidebar").style.width = "100%";
    documentElement.getElementById("main").style.display = "none";
    documentElement.getElementById("mySidebar").style.marginLeft = "0";
  };

  const onSignOut = () => {
    const rUrl = `/login?return_url=${encodeURIComponent(window.location.pathname)}`;
    dispatch(resetLoginUser());
    clearAll();
    navigate(rUrl)
  };

  return (
    <header className="mainHeader fixed-top searchCategories">
      <div className="container-fluid">
        <div className="headerWrapper">
          <div
            className="logoSection"
            onClick={() => {
              navigate("/");
            }}
          >
            <a className="jetLogo">
              <img src={JetLogo} alt="JetLogo" />
            </a>
            <a className="maverickLogo">
              <img
                src={require("../../Assets/Images/maverick-logo.png")}
                alt="maverickLogo"
              />
            </a>
          </div>
          <div className="middleSection">
            <GlobalSearchInput />
            <Notifications />
            <Button
              fillMode="link"
              className="menuIconBtn"
              type="submit"
              onClick={openSidebar}
            ></Button>
          </div>

          <div className="userSec">
            <div className="dropdown">
              <Button fillMode="outline">
                <div className="userPic">
                  {userImage ? (
                    <img src={userImage} />
                  ) : (
                    <span>
                      {authUser?.first_name || authUser?.last_name
                        ? `${authUser?.first_name
                            ?.charAt(0)
                            .toUpperCase()}${authUser?.last_name
                            ?.charAt(0)
                            .toUpperCase()}`
                        : ""}
                    </span>
                  )}
                </div>
                <div className="userNameSec">
                  {/* <span>&nbsp;</span> */}
                  <strong style={{margin: 'auto'}}>{`${authUser?.first_name || ""} ${
                    authUser?.last_name?.charAt(0) || ""
                  }.`}</strong>
                </div>
              </Button>
              <ul className="dropdown-menu">
                {permission?.menu?.some(
                  (menu: any) => menu["module"] === "SYSTEMSETTING"
                ) ? (
                  <li>
                    <Link to="/system-setting" className="dropdown-item">System Setting</Link>
                  </li>
                ) : (
                  <></>
                )}

                {/* <li>
                  <a className="dropdown-item" href={void 0}>
                    My Profile
                  </a>
                </li> */}

                <li>
                  <a
                    className="dropdown-item"
                    href={void 0}
                    onClick={onSignOut}
                  >
                    Sign Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

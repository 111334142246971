import React from "react";
import { Navigate, Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {isEmpty, find} from "lodash";
import { getAllPermission } from "../../../redux/action/user";
import { ROUTE_URLS, buildURL } from "../../../constant/routeURLs";
import { GLOBAL_PERMISSIONS, PERMISSIONS } from "../../../constant/permissions";
import ContactDetailHeader from "./Header";
import UserLeftBar from "./UserLeftBar";
import Tabs from "../../../components/atoms/tab/tab";
import Loader from "../../../components/common/Loader";
import { getBrokerInformation, getContactInformation, getPersonalInformation, getSpouseInformation, resetContactInformation } from "../../../redux/action/contact";
import { URLS } from "../../../utils/url";
import useAxios from "../../../service/Service";
import { allContactStatusView } from "../../../constant/constant";
import { AreaOfInterest } from "../../../utils/constant";
import { getContactFullName } from "../../../utils/utils";

export enum CONTACT_DETAILS_TAB_LIST {
    DETAILS = "details",
    TRIPS = "trips",
    JET_CARDS = "jet-cards",
    PASSENGERS = "passengers",
    TASKS = "tasks",
    NOTES = "notes",
    WEB_INQUIRIES = "web-inquiries",
};

interface ITab {
    title: string,
    key: CONTACT_DETAILS_TAB_LIST,
    navigateUrl: string
};

type ContactDetailsContextType = {
    contactNotes: any,
    personalInfo: any,
    setPersonalInfo: (value: any) => void,
    setContactNotes: (value: any) => void,
};

const ContactScreen: React.FC = () => {
    const { contactId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { personalInformation, contactInformation } = useSelector((state: any) => state.contacts);
    const { permission } = useSelector((state: any) => state.auth);
    const [tabList, setTabList] = React.useState<Array<ITab> | null>(null);
    const [selectedTab, setSelectedTab] = React.useState<string | null>(null);
    const [contactNotes, setContactNotes] = React.useState<Array<any>>([]);
    const [personalInfo, setPersonalInfo] = React.useState<any>({});
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        GetContactNotes();
        getContactDetails();
        dispatch(getAllPermission());
        return () => dispatch(resetContactInformation());
    }, [contactId]);

    React.useEffect(() => {
        let rowData: Array<ITab> = [];
        const roleGlobalPermission = permission?.permissions?.[0] || [];
        rowData.push({
            title: "Details",
            key: CONTACT_DETAILS_TAB_LIST.DETAILS,
            navigateUrl: buildURL(ROUTE_URLS.CONTACT_DETAILS, { contactId }),
        });
        if (roleGlobalPermission.includes(GLOBAL_PERMISSIONS.VIEWTRIP)) {
            rowData.push({
                title: "Trips",
                key: CONTACT_DETAILS_TAB_LIST.TRIPS,
                navigateUrl: buildURL(ROUTE_URLS.CONTACT_DETAILS_TRIPS_TAB, { contactId }),
            });
        }
        if (roleGlobalPermission.includes(GLOBAL_PERMISSIONS.VIEW_JET_CARD_AGREEMENT)) {
            rowData.push({
                title: "Jet Cards",
                key: CONTACT_DETAILS_TAB_LIST.JET_CARDS,
                navigateUrl: buildURL(ROUTE_URLS.CONTACT_DETAILS_JETCARD_TAB, { contactId }),
            });
        }
        if (roleGlobalPermission.includes(GLOBAL_PERMISSIONS.VIEW_CONTACT_PASSENGER)) {
            rowData.push({
                title: "Passengers",
                key: CONTACT_DETAILS_TAB_LIST.PASSENGERS,
                navigateUrl: buildURL(ROUTE_URLS.CONTACT_DETAILS_PASSENGERS_TAB, { contactId }),
            });
        }
        rowData.push({
            title: "Tasks",
            key: CONTACT_DETAILS_TAB_LIST.TASKS,
            navigateUrl: buildURL(ROUTE_URLS.CONTACT_DETAILS_TASKS_TAB, { contactId }),
        });
        rowData.push({
            title: "Notes",
            key: CONTACT_DETAILS_TAB_LIST.NOTES,
            navigateUrl: buildURL(ROUTE_URLS.CONTACT_DETAILS_NOTES_TAB, { contactId }),
        });
        rowData.push({
            title: "Web Inquiries",
            key: CONTACT_DETAILS_TAB_LIST.WEB_INQUIRIES,
            navigateUrl: buildURL(ROUTE_URLS.CONTACT_DETAILS_WEBINQUIRIES_TAB, { contactId }),
        });
        setTabList(rowData);
    }, [permission, contactId]);
  
    React.useEffect(() => {
        if (tabList !== null && !isEmpty(tabList)) {
            if (location.pathname === "/") {
                navigate(tabList[0].navigateUrl);
            } else {
                let selectedTab = find(tabList, {navigateUrl: location.pathname});
                if (!selectedTab && location.pathname.includes("jet-cards")) {
                    selectedTab = tabList[2];
                }
                setSelectedTab(selectedTab ? selectedTab.key : tabList[0].key);
            }
        }
    }, [tabList, location]);

    const GetContactNotes = async () => {
        const { response, status, error } = await useAxios({
          method: "get",
          url: `${URLS.GetContactNotesByClientId}/${contactId}`,
          headers: JSON.stringify({ accept: "*/*" }),
          body: JSON.stringify({}),
        });
    
        if (response.responseCode === 200) {
          setContactNotes(response.responseData);
        }
      };

    const getContactDetails = () => {
        setLoading(true);
        dispatch(
          getPersonalInformation(contactId, async (permission: any) => {
            const promise = [
              dispatch(getContactInformation(contactId)),
              dispatch(getBrokerInformation(contactId)),
            ];
            await Promise.all(promise);
            setLoading(false);
          })
        );
      };

      React.useEffect(() => {
        if (Object.keys(personalInformation).length) {
          const data = { ...personalInformation };
          const areaOfInterest =
            data?.areaOfInterest !== "{}"
              ? data?.areaOfInterest
                  ?.replace("{", "")
                  .replace("}", "")
                  .split(",")
                  .map((item: string, index: number) =>
                    AreaOfInterest.find((obj) => obj.key === item)
                  )
              : [];
          data.areaOfInterest = areaOfInterest;
          data.refferedByName = { id: data.refferedBy, text: data.refferedByName };
          data.contactStatus = allContactStatusView[data.contactStatus];
          data.fullName = getContactFullName(personalInformation);
          if (contactInformation?.contactInformation?.emails) {
            data.preferredEmail =  find(contactInformation.contactInformation.emails, { preferred: true }).email;
          } else {
            data.preferredEmail = null;
          }
          setPersonalInfo(data);
        }
      }, [personalInformation, contactInformation?.contactInformation]);

    return (
        <div className="container-fluid">
            {loading && <Loader />}
            <ContactDetailHeader clientName={personalInfo.fullName} preferredEmail={personalInfo.preferredEmail} />
            <div className="userDetailsWrapper">
                <div className="row">
                    <UserLeftBar
                        isProfileUploadPermission={
                            personalInfo?.permissions?.[
                                PERMISSIONS.EDIT_CONTACT_PERSONALINFO
                            ]
                        }
                        contactNotes={contactNotes}
                    />
                    <div className="col-lg-8 col-xl-9">
                        {tabList !== null && !isEmpty(tabList) && (
                            <Tabs isLink activeKey={selectedTab} dataTestId="tab-id" tabList={tabList}>
                                {selectedTab && (
                                    <Outlet
                                        context={{
                                            contactNotes,
                                            setContactNotes,
                                            personalInfo,
                                            setPersonalInfo,
                                        }}
                                    />
                                )}
                            </Tabs>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(ContactScreen);

export const useContactDetails = () => {
    return useOutletContext<ContactDetailsContextType>();
}
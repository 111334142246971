import * as type from '../../constant/redux-const';
import { DEFAULT_PAGE_SIZE } from '../../utils/constant';

const initialTripFilter = {
    tripDisplayName: "",
    name: "",
    broker: [],
    tripStartDate: "",
    tripEndDate: "",
    operatorName: [],
    tripStatus: [],
    tripType: "",
    revisedCost: "",
    splitTrip: "",
};

const initialState: any = {
  operatorList: [],
  operatorFilter: initialTripFilter,
  dataState: {
    take: DEFAULT_PAGE_SIZE,
    skip: 0,
    sort: [{ field: "name", dir: "asc" }],
  },

  listFilter: {
    name: '',
    airportCode: '',
    city:"",
    stateName:[],
    countryName:[],
  },
  listFilterloaded: false,
  listDataState: {
    filter: {
      filters: [],
      logic: "and",
    },
    sort: [{ field: "name", dir: "asc" }],
    take: DEFAULT_PAGE_SIZE,
    skip: 0,
  },
};

const FboReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case type.UPDATE_OPERATOR_FINANCE_LIST:
            return {
                ...state,
                operatorList: action.payload
            }
        case type.UPDATE_OPERATOR_FINANCE_LIST_FILTER:
            return {
                ...state,
                operatorFilter: action.payload
            }
        case type.UPDATE_OPERATOR_FINANCE_LIST_DATA_STATE:
            return {
                ...state,
                dataState: action.payload
            }
        case type.DM_OPERATOR_LOADED_AIRPORT_FBO: 
            return {
                ...state,
                listDataState: action.payload,
            }
        case type.DM_OPERATOR_FILTER_CHANGE_HANDLER_AIRPORT_FBO:
            return {
                ...state,
                listFilter: {...action.payload},
            };
        default:
            return state;
    }
}


export default FboReducer;

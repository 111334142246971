import {
    INITIALIZE_TRIP_MANAGE_FORMIK_FORM,
    RESET_TRIP_MANAGE_FORMIK_FORM
} from "../../constant/redux-const";
import { IPassenger } from "../../service/contact";

const initialState: any = {
    tripManage: {
        brokerIds: [],
        handledByIds: [],
        legOption: null
    },
};

const formikManageTripReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case INITIALIZE_TRIP_MANAGE_FORMIK_FORM: {
            if (action.payload) {
                const tripOverviewData = action.payload;
                const tripLegOptions = action.payload?.tripLegOption
                const optionLegs = tripLegOptions?.optionLegs?.map((item: any) => {
                    const aircraftName = {
                        id: item?.aircraft?.id || null,
                        name: item?.aircraft?.name || null,
                    }
                    const { passengers, ...otherValues } = item
                    return {
                        ...otherValues,
                        passengerIds: passengers ? passengers.map((item: IPassenger) => item.id) : [],
                        operatorName: item?.aircraft?.operator || null,
                        aircraftName: item?.aircraft ? aircraftName : null,
                        departureFBO: item?.departureFBO || null,
                        arrivalFBO: item?.arrivalFBO || null,
                        groundNeeds: item?.groundNeeds || '',
                        cateringDetails: item?.catering || '',
                        notes: item?.additionalNotes || '',
                    }
                }) || []
                // delete tripLegOptions?.optionLegs

                const handledByIds: Array<number> = [];
                const brokerIds: Array<number> = tripOverviewData?.brokers?.map((broker: any) => {
                    if (broker.isHandler) {
                        handledByIds.push(broker.id);
                    }
                    return broker.id;
                });

                return {
                    ...state,
                    tripManage: {
                        ...state.tripManage,
                        brokerIds,
                        handledByIds,
                        legOption: {
                            ...tripLegOptions,
                        },
                        legs: optionLegs
                    }
                };
            }
            return state;
        }
        case RESET_TRIP_MANAGE_FORMIK_FORM: {
            return initialState;
        }
        default:
            return state;
    }
};

export default formikManageTripReducer;

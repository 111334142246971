import Axios, { AxiosService } from "./Service";
import { URLS } from "../utils/url";
import {
  setGlobalSearchResults,
  setIsSearchingTrue,
} from "../redux/action/globalsearch";
import moment from "moment";

interface IFetchGlobalSearchAPIRequest {
  search_string: string;
  search_type: string;
  search_code: string;
  userid: string;
}

export const fetchGlobalSearchAPI = async (
  source: any,
  searchCategory: string,
  searchValue: string,
  userId: string,
  callback: Function,
  clicked: boolean = false,
) => {
  const payload: IFetchGlobalSearchAPIRequest = {
    search_code: clicked ? moment().format('x') : '0',
    search_string: searchValue.split(" ").join("|"),
    search_type: searchCategory,
    userid: userId,
  }

  AxiosService().post( `${URLS.GlobalSearch}`, payload, {
    cancelToken: source.token,
  }).then((result) => {
    if (
      result?.data &&
      result?.data?.responseCode === 200 &&
      result?.data?.responseData
    ) {
      callback(result?.data?.responseData, payload.search_code);
    }
  });
};

export const fetchGlobalSearchPageAPI = async (
  searchValue: string,
  dispatch: Function
) => {
  dispatch(setIsSearchingTrue(true));
  dispatch(setGlobalSearchResults(null));

  const result = await Axios({
    method: "get",
    url: `${URLS.GlobalSearch}/${searchValue}/ALL`,
  });

  dispatch(setIsSearchingTrue(false));

  if (
    result &&
    result.response?.responseCode === 200 &&
    result.response?.responseData
  ) {
    dispatch(setGlobalSearchResults(result.response.responseData));
  }
};

export const fetchCountForGlobalSearchResult = async (searchCode: string) => {
  return await Axios({
    url: `/globalsearch/count/${searchCode}`,
    method: 'get'
  });
}

import {
    INITALIZE_TRIP_INQUIRY_FORMIK_FORM,
    INITALIZE_SPECIAL_REQUEST_FORMIK_FORM,
    RESET_TRIP_INQUIRY_FORMIK_FORM,
    UPDATE_TRIP_INQUIRY_FORMIK_FORM_EMAIL
} from "../../constant/redux-const";

export const initalizeTripInquiryFormikForm: any = (tripOverviewData: any) => {
    return {
        type: INITALIZE_TRIP_INQUIRY_FORMIK_FORM,
        payload: tripOverviewData,
    };
};
export const updateTripInquiryFormikFormEmail: any = (email: string | null) => {
    return {
        type: UPDATE_TRIP_INQUIRY_FORMIK_FORM_EMAIL,
        payload: email,
    };
};

export const initalizeSpecialRequestFormikForm: any = (tripSpecialRequestData: any) => {
    return {
        type: INITALIZE_SPECIAL_REQUEST_FORMIK_FORM,
        payload: tripSpecialRequestData,
    };
};

export const resetTripInquiryFormikForm: any = () => {
    return {
        type: RESET_TRIP_INQUIRY_FORMIK_FORM,
    };
};
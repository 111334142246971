export const debounceTime = 700;
export const userContactRole = ["Personal", "Business", "Other"];
export const contactRelationship = [
  {key: "Service_Desk", value: "Service Desk"},
  {key: "Secretory", value: "Secretory"},
  {key: "Sales_Department", value: "Sales Department"},
  {key: "Personal_Assistant", value: "Personal Assistant"},
  {key: "Other", value: "Other"},
]
export const userSocialMediaChannelRole = [
  { key: "Linkdin", value: "Linkedin" },
  { key: "Twitter", value: "Twitter" },
  { key: "Facebook", value: "Facebook" },
  { key: "Instagram", value: "Instagram" },
];
export const userRole = ["Leader", "Scheduler", "Broker", "Developer", "Designer"];
export const fileStatuses = [
  "UploadFailed",
  "Initial",
  "Selected",
  "Uploading",
  "Uploaded",
  "RemoveFailed",
  "Removing",
];
export const ContactType = ["Individual", "Business"];
export const ContactStatus = ["Initiated", "Potential Client", "Client", "Reject"];
export const ContactStatusData: any = {
  "New Contact": "NewContact",
  Initiated: "Initiated",
  "Potential Client": "PotentialClient",
  Client: "Client",
  Reject: "Reject",
};
export const AreaOfInterest = [
  { id: 1, text: "Jet Card", key: "JetCard" },
  { id: 2, text: "Charter Trip", key: "Charter" },
];

export const splitCamalCaseHandler = (str: string) => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
};

export const documentTypes = ["DrivingLicense", "Passport", "Other"];

export const InitialPersonalInfo = {
  contactType: "",
  refferedByName: {},
  areaOfInterest: [],
  contactStatus: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: null,
  businessName: "",
  businessTitle: "",
  businessWebsite: "",
};

export const InitialContactInfo = {
  email: "",
  phone: "",
  preferredMethodOfCom: {
    methodofcom: [],
  },
  contactAddress: {
    address: {
      address: "",
      address2: "",
      city: "",
      zip: "",
      phone: "",
      phoneExt: "",
      fax: "",
      cell: "",
      state: null,
      country: null,
      addressesTypes: null,
    },
    pointOfContacts: {
      name: "",
      email: "",
      phone: "",
      relationshipType: null,
    },
  },
};

export const InitialBrokerInfo = {
  broker: {
    brokers: [],
  },
  assistant: {
    assistant: [],
  },
};

export enum PromotionTypes {
  "FreeHours" = "FreeHours",
  "CustomRate" = "CustomRate",
}

export const PromotionTypesText = {
  [PromotionTypes.FreeHours]: "Free Hours",
  [PromotionTypes.CustomRate]: "Custom Rate",
};

export enum PromotionStatus {
  "NotStarted" = "NotStarted",
  "Live" = "Live",
  "Paused" = "Paused",
  "End" = "End",
}

export const PromotionStatusText = {
  [PromotionStatus.NotStarted]: "Not Started",
  [PromotionStatus.Live]: "Live",
  [PromotionStatus.Paused]: "Paused",
  [PromotionStatus.End]: "End",
};

export const ApplicableCondition = [
  { key: "Equal", value: "Equal" },
  { key: "GreaterThan", value: "Greater than" },
  { key: "LessThan", value: "Less than" },
  { key: "GreateThanOrEqualTo", value: "Greater than or equal to" },
  { key: "LessThanOrEqualTo", value: "Less than or equal to" },
];

export const paymentMethodData = [
  { key: "Check", value: "Check" },
  { key: "CreditCard", value: "Credit Card" },
  { key: "Wire", value: "Wire" },
  { key: "Other", value: "Other" },
];

export const operatorPaymentTypes = [
  { key: "WIRE_SETUP", value: "Wire Setup" },
  { key: "WIRED", value: "Wired" },
  { key: "CHECK", value: "Check" },
  { key: "CREDIT_CARD", value: "Credit Card" },
];

export const paidByList = [
  { id: 1, key: "BLANK", value: "Blank" },
  { id: 2, key: "CHECK", value: "Check" },
  { id: 3, key: "CREDIT_CARD", value: "Credit Card" },
  { id: 4, key: "WIRED", value: "Wired" },
  { id: 5, key: "WIRE_SETUP", value: "Wire Setup" },
];
export enum JetCardTypes {
  "All In" = "All In",
  "All Access" = "All Access",
}

export enum JetCardStatuses {
  "Draft" = "Draft",
  "Sent" = "Sent",
  "Payment Due" = "Payment Due",
  "Live" = "Live",
  "Exhausted" = "Exhausted",
  "Inactive" = "Inactive",
  "Rejected" = "Rejected",
}

export const AGREMENT_MAIL_CONTENTS_ID = process.env.REACT_APP_AGREMENT_MAIL_CONTENTS_ID ?? 1;
export const GRAY_OAKS_AGREMENT_MAIL_CONTENTS_ID = process.env.REACT_APP_GRAY_OAKS_AGREMENT_MAIL_CONTENTS_ID ?? 29;
export const AGREMENT_TANDC_ID = process.env.REACT_APP_AGREMENT_TANDC_ID ?? 1;
export const GRAY_OAKS_AGREMENT_TANDC_ID = process.env.REACT_APP_GRAY_OAKS_AGREMENT_TANDC_ID ?? 6;

export const CommonMessages = {
  jetCardAgrement: {
    draftMessage: "Success! Agrement has been created",
    previewDialogText: "Previewing the agreement will save the agreement. Do you want to continue?",
    cancelDialogText: "Do you want to cancel? Any unsaved data will be lost.",
    successMessage: "Success! Agrement has been created",
  },
  systemManagement: {
    role: {
      deleteDialog: {
        usersLinkedMessage:
          "Users are still linked with this role. Please unlink users before deleting.",
        usersNotLinkedMessage:
          "Are you sure you want to permanently delete this role from the system?",
      },
    },
  },
  editTripLeg:{
   tripOptionEditLeg :"Updates made here will directly reflect in Scenarios, Options, Proposals. Please reach out to scheduling team for pricing impacts.",
   tripCharterEditLeg :"Updates made here will directly reflect in the Contracts at the same price. Please ensure client consent.",
   tripJCEditLeg :"Updates made here will directly reflect in the Contracts. Please ensure client consent.",
  }
};

export const brokerType = ["Individual", "Salaried"];

export const JetCardStatusClasses: any = {
  [JetCardStatuses.Live]: "live",
  [JetCardStatuses["Payment Due"]]: "paymentDue",
  [JetCardStatuses.Rejected]: "rejected",
  [JetCardStatuses.Sent]: "sent",
  [JetCardStatuses.Draft]: "draft",
  [JetCardStatuses.Exhausted]: "exhausted",
  [JetCardStatuses.Inactive]: "inactive",
};

export const URTFilter = {
  userFilter: {
    role: [],
    team: [],
    toDate: null,
    fromDate: null,
    name: "",
    status: [],
  },
};

export enum TransactionType {
  "First Payment Entry" = 1,
  "Refill Jet Card",
  "Manual Debit",
  "Manual Credit",
  "Trip Charge",
  "Trip Credit",
}

export const tripTypeList = [
  { id: 1, text: "Charter" },
  { id: 2, text: "Jet Card" },
];

export const eteConfiguration =[
  {id:1 , text : "5%" },
  {id:2 , text : "10%" },
  {id:3 , text : "15%" },
  {id:4 , text : "20%" },
  {id:5 , text : "25%" },
]

export const status =[
  {id:1 , text : "Approved" },
  {id:2 , text : "Do Not use" },
]

export const tripStatusList = [
  { id: 14, key: "-", text: "Draft" },
  { id: 1, key: "Quote Open", text: "Scheduling" },
  { id: 12, key: "Jet Card Pending", text: "Pricing and Proposal" },
  { id: 2, key: "Quote to Broker", text: "Quote Review" },
  { id: 3, key: "Sent to Client", text: "Client review" },
  { id: 4, key: "Accepted", text: "Accepted" },
  { id: 15, key: "-", text: "Partially Assigned" },
  { id: 5, key: "Assignment Made", text: "Assignment Made" },
  { id: 21, key: "-", text: "Ongoing Trip" },
  { id: 13, key: "Broker to Review", text: "Trip review" },
  { id: 7, key: "Completed", text: "Finance Review" },
  { id: 8, key: "Finalized", text: "Finalized" },
  { id: 9, key: "Cancelled", text: "Cancelled" },
  // { id: 10, key: "Cancelled with Cost", text: "Cancelled (On Hover, No cost will be displayed)" },
  // {
  //   id: 11,
  //   key: "Cancelled and Finalized",
  //   text: "Cancelled (On Hover, Finalized will be displayed)",
  // },
]

export const TripStatusClass: any = {
  1: "SchedulingPricingProposal",
  2: "QuoteReview",
  3: "ClientReview",
  4: "Accepted",
  5: "AssignmentMade",
  7: "FinanceReview",
  8: "Finalized",
  9: "Cancelled",
  10: "Cancelled",
  11: "Cancelled",
  12: "SchedulingPricingProposal",
  13: "TripReview",
  14: "Draft",
  15: "PartiallyAssigned",
  16: "OngoingTrip",
  17: "Scheduling",
  18: "Scheduling",
  19: "Scheduling",
  20: "Scheduling",
};

export const TripStatus: any = {
  1: "Scheduling",
  2: "Quote Review",
  3: "Client Review",
  4: "Accepted",
  5: "Assignment Made",
  7: "Finance Review",
  8: "Finalized",
  9: "Cancelled",
  10: "Cancelled",
  11: "Cancelled",
  12: "Pricing and Proposal",
  13: "Trip Review",
  14: "Draft",
  15: "Partially Assigned",
  16: "Trip Review",
  17: 'Quote Review',
  18: "Quote Review",
  19: 'Pricing and Proposal',
  20: "Pricing and Proposal",
  21: "Ongoing Trip"
};

export const aircraftCategoryList: any = {
  1: "Piston",
  2: "Turbo Prop",
  3: "VLJ",
  4: "Light",
  5: "Mid",
  6: "Super Mid",
  7: "Heavy",
  8: "Other",
};

export const documentTypesConst: Array<{ value: string; text: string }> = [
  { value: "DrivingLicense", text: "Driving License" },
  { value: "Passport", text: "Passport" },
  { value: "Other", text: "Other" },
];


export const GRID_PAGE_SIZES = [10, 25, 50];
export const DEFAULT_PAGE_SIZE = 10;

export const USA_COUNTRY_ID = 233;

export const TooltipMessage = {
  UPDATE_JET_CARD_DISPLAY_NAME: "Update Jet card Display name",
};

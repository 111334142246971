import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { AutoComplete, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import {
  setGlobalSearchCategory,
  setGlobalSearchCode,
  setGlobalSearchResults,
  setGlobalSearchText,
  setGlobalSerachLoading,
} from "../../../redux/action/globalsearch";
import { fetchGlobalSearchAPI } from "../../../service/GlobalSearchServices";
import { debounceTime } from "../../../utils/constant";
import { isMobile } from "../../../helper/helper";
import CustomMultiSelectTree, {
  IOption,
} from "../../../components/atoms/multi-dropdown/CustomMultiSelectTree";
import SearchResultItem from "./SearchResultItem";
import axios from "axios";
import { Error } from "@progress/kendo-react-labels";

enum CategoryOptions {
  ALL,
  CONTACT,
  TRIPS,
  JETCARD,
}

export const categoryOptions: Array<IOption> = [
  { id: 1, text: "All", key: "ALL" },
  { id: 2, text: "Contact", key: "CONTACT" },
  { id: 3, text: "Trips", key: "TRIPS" },
  { id: 4, text: "Jet Card", key: "JETCARD" },
];

const GlobalSearchInput: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const autoCompleteRef = React.useRef();
  const [isOpenedSearchOnMobile, setIsOpenedSearchOnMobile] =
    React.useState(false);
  const [isOpenedAutoSearchList, setIsOpenedAutoSearchList] =
    React.useState(false);
  const [selecteSearchCategory, setSelecteSearchCategory] = React.useState<
    Array<IOption>
  >([categoryOptions[CategoryOptions.ALL]]);
  const [searchText, setSearchText] = React.useState("");
  const [searchTextOnEnter, setSearchTextOnEnter] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [autoSearchList, setAutoSearchList] = React.useState<Array<object>>([]);
  const [isMobileScreen, setIsMobileScreen] = React.useState(isMobile);
  const { authUser } = useSelector((state: any) => state.auth);

  React.useEffect(() => {
    if (selecteSearchCategory.length && searchText.length > 2) {
      const searchCategory = selecteSearchCategory
        .map((option) => option.key)
        .join(",");
      setIsLoading(true);
      debouncedGetSuggestionData(searchCategory, searchText);
    } else {
      setAutoSearchList([]);
    }
  }, [searchText, selecteSearchCategory]);

  const handleSearchInputKeydown = React.useCallback((e: any) => {
    if (e.keyCode === 13 && !isLoading) {
      setSearchTextOnEnter(e.target.value);
    }
  }, [setSearchTextOnEnter]);

  const onClearButtonClickHandler = () => {
    setSearchText("");
    setIsOpenedAutoSearchList(false);
  }

  React.useEffect(() => {
    try {
      const element = document.querySelector(
        ".autoSelectSearch .k-input-inner"
      );
      element?.addEventListener("keydown", handleSearchInputKeydown);
      return () => {
        element?.removeEventListener("keydown", handleSearchInputKeydown);
      };
    } catch (e) {
      console.log(e);
    }
  }, [handleSearchInputKeydown]);

  React.useEffect(() => {
    try {
      const clearButton = document.querySelector(
        ".autoSelectSearch .k-clear-value"
      );
      clearButton?.addEventListener("click", onClearButtonClickHandler);
      return () => {
        clearButton?.removeEventListener("click", onClearButtonClickHandler);
      };
    } catch (e) {
      console.log(e);
    }
  }, [onClearButtonClickHandler]);



  React.useEffect(() => {
    if (searchTextOnEnter) {
        const searchCategory = selecteSearchCategory.map((option) => option.key).join(",");
        dispatch(setGlobalSerachLoading(true));
        onSearchClick(searchTextOnEnter, searchCategory, () => {
            setSearchTextOnEnter(null);
            dispatch(setGlobalSerachLoading(false));
        });
    }
  }, [searchTextOnEnter]);

  const onMultiSelectTreeChange = React.useCallback((items: Array<IOption>) => {
    if(items.length === 0) {
      setSelecteSearchCategory([categoryOptions[0]]);
    } else {
      setSelecteSearchCategory(items);
    }
  }, []);

  const onMultiSelectTreeBlur = React.useCallback(() => {
    if (
      !selecteSearchCategory.length ||
      selecteSearchCategory.find(
        (category) => category.key === categoryOptions[CategoryOptions.ALL].key
      )
    ) {
      setSelecteSearchCategory([categoryOptions[CategoryOptions.ALL]]);
    }
    dispatch(setGlobalSearchCategory(selecteSearchCategory));
  }, [selecteSearchCategory]);

  const toggleSearch = React.useCallback(() => {
    setIsOpenedSearchOnMobile((oldState) => !oldState);
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      const isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;
      setIsMobileScreen(isMobile);
    }, 500)

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  const renderSearchResultItem = React.useCallback(
    (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => (
      <SearchResultItem li={li} itemProps={itemProps} />
    ),
    []
  );

  const handleAutoCompleteBlur = React.useCallback(() => {
    setIsOpenedAutoSearchList(false);
  }, []);

  const handleAutoCompleteFocus = React.useCallback(() => {
    if (searchText.length > 2) {
      setIsOpenedAutoSearchList(true);
    }
  }, [searchText]);

  const onChange = React.useCallback(
    (e: any) => {
      if (e?.syntheticEvent?.type === "change") {
        setSearchText(e.value);
        if (e.value?.length > 2) {
          setIsOpenedAutoSearchList(true);
        } else {
          setIsOpenedAutoSearchList(false);
        }
      } else if (e?.syntheticEvent?.target.className === "k-icon k-i-x") {
        setIsOpenedAutoSearchList(false);
        setSearchText("");
        dispatch(setGlobalSearchText(""));
        dispatch(setGlobalSearchResults(null));
      } else if (e?.syntheticEvent?.type === "click") {
        setIsOpenedAutoSearchList(false);
      }
    },
    [selecteSearchCategory]
  );

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const getGlobalSearchData = async (
    searchCategory: string,
    searchValue: string,
    clicked: boolean = false
  ) => {
    await fetchGlobalSearchAPI(
      source,
      searchCategory,
      searchValue,
      authUser?.id ?? "",
      (result: any, searchCode: string) => {
        if (result && Object.keys(result).length) {
          const allAutoCompleteData = [
            ...result["CONTACT"][0],
            ...result["JETCARD"][0],
            ...result["TRIP"][0],
          ];
          const autoCompleteData: Array<object> = allAutoCompleteData?.slice(
            0,
            8
          );
          setAutoSearchList(autoCompleteData);
          if (searchCode != "0") {
            dispatch(setGlobalSearchCode(searchCode));
          }
        } else {
          setAutoSearchList([]);
        }
        setIsLoading(false);
      },
      clicked
    );
  };

    const debouncedGetSuggestionData = React.useCallback(
        debounce((searchCategory, searchValue) => 
            getGlobalSearchData(searchCategory, searchValue), debounceTime
        ), []);

  const onSearchClick = async (text: string = searchText, searchCat?: any, callback?: () => void) => {
    dispatch(setGlobalSearchCode(null));
    if (text.length > 2) {
      setIsLoading(true);
      const searchCategory = selecteSearchCategory
        .map((option) => option.key)
        .join(",");
      await getGlobalSearchData(searchCat ? searchCat : searchCategory, text, true);
      callback && callback();
      setIsOpenedAutoSearchList(false);
      dispatch(await setGlobalSearchText(text));
      navigate("/global-search-results");
    }
  };

  return (
    <>
      <div
        className="searchForm"
        style={{
          display: isMobileScreen && !isOpenedSearchOnMobile ? "none" : "flex",
        }}
      >
        <div className="selectCategoryOptions">
          <CustomMultiSelectTree
            value={selecteSearchCategory}
            data={categoryOptions}
            onMultiSelectTreeChange={onMultiSelectTreeChange}
            onMultiSelectTreeBlur={onMultiSelectTreeBlur}
            otherProps={{
              placeholder: "Select options...",
              ariaLabelledBy: "checkbox-dropdown-label",
              ariaDescribedBy: "checkbox-dropdown-description",
              popupSettings: {
                className: "categoryListPopup",
              },
            }}
          />
        </div>
        <AutoComplete
            loading={isLoading}
            data={autoSearchList}
            opened={!isLoading && isOpenedAutoSearchList}
            value={searchText}
            clearButton={true}
            onChange={onChange}
            onBlur={handleAutoCompleteBlur}
            onFocus={handleAutoCompleteFocus}
            itemRender={renderSearchResultItem}
            className="customInput autoSelectSearch"
            placeholder="Search contacts, trips, jetcards..."
            popupSettings={{
                className: "searchAutoCompleteList",
            }}
            ref={autoCompleteRef}
        />
        <Error>{searchText.length > 255 && "Enter max 255 characters."}</Error>
        <Button
          fillMode="link"
          className="headerCloseBtn"
          type="button"
          onClick={toggleSearch}
        />
        <div className="seachOverlay" onClick={toggleSearch}></div>
        <Button
          disabled={searchText.length < 3 || searchText.length > 255}
          fillMode="link"
          className="searchText d-block d-md-none"
          type="button"
          onClick={() => { onSearchClick() }}
        >
          Search
        </Button>
      </div>
      <Button
        disabled={searchText.length < 3 || isLoading || searchText.length > 255}
        fillMode="link"
        className="searchText d-none d-md-block"
        type="button"
        onClick={() => { onSearchClick() }}
      >
        Search
      </Button>
      <Button
        fillMode="link"
        className="headerSearchBtn d-block d-md-none"
        type="button"
        onClick={toggleSearch}
      ></Button>
    </>
  );
};

export default React.memo(GlobalSearchInput);

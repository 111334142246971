import * as type from '../../constant/redux-const';
import { DEFAULT_PAGE_SIZE } from '../../utils/constant';

const initialTripFilter = {
    tripDisplayName: "",
    clientName: "",
    broker: [],
    tripStartDate: "",
    tripEndDate: "",
    operatorName: [],
    tripStatus: [],
    paidBy :[],
    tripType: "",
    revisedCost: "",
    splitTrip: "",
    positiveBalanceDue :false,
    wireList : true
};

const initialState: any = {
  operatorList: [],
  operatorFilter: initialTripFilter,
  dataState: {
    take: DEFAULT_PAGE_SIZE,
    filter: {
      filters: [
        {
          field: "operatorCost",
          filters: [
            {
              field: "balanceDue",
              operator: "gt",
              value: "0",
            },
            {
              field: "totalCost",
              operator: "eq",
              value: "0",
            },
          ],
          logic: "or",
        },
      ],
      logic: "end",
    },
    skip: 0,
    sort: [{ field: "tripId", dir: "desc" }],
  }
};

const OperatorReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case type.UPDATE_OPERATOR_FINANCE_LIST:
            return {
                ...state,
                operatorList: action.payload
            }
        case type.UPDATE_OPERATOR_FINANCE_LIST_FILTER:
            return {
                ...state,
                operatorFilter: action.payload
            }
        case type.UPDATE_OPERATOR_FINANCE_LIST_DATA_STATE:
            return {
                ...state,
                dataState: action.payload
            }
        default:
            return state;
    }
}


export default OperatorReducer;

const Permissions = (permissions: any) => {
  let isAllow = false;
  let permission: any = JSON.parse(localStorage.getItem("persist:root") || "");
  let allPermissions =
    JSON.parse(permission.auth)?.permission?.permissions || [];
  if (allPermissions.length) {
    allPermissions = allPermissions[0];
  }
  if (permissions) {
    isAllow = false;
    permissions.map((v: any) => {
      let per = v.includes("&&");
      if (per) {
        let andPer = v.split("&&");
        let count = 0;
        andPer.map((vv: any) => {
          let isPer = allPermissions.includes(vv);
          if (isPer) {
            count++;
          }
        });
        if (andPer.length == count) {
          isAllow = andPer.length == count;
        }
      } else {
        let isPer = allPermissions.includes(v);
        if (isPer) {
          isAllow = isPer;
        }
      }
    });
  }

  return isAllow;
};

export default Permissions;

import moment from "moment";
import * as type from "../../constant/redux-const";
import { DEFAULT_PAGE_SIZE } from "../../utils/constant";

export const defaultAppliedStatusArray = [
    { "id": 1, "key": "Quote Open", "text": "Scheduling" },
    { "id": 2, "key": "Quote to Broker", "text": "Quote Review" },
    { "id": 3, "key": "Sent to Client", "text": "Client review" },
    { "id": 4, "key": "Accepted", "text": "Accepted" },
    { "id": 5, "key": "Assignment Made", "text": "Assignment Made" },
    { "id": 7, "key": "Completed", "text": "Finance Review" },
    { "id": 8, "key": "Finalized", "text": "Finalized" },
    { "id": 12, "key": "Jet Card Pending", "text": "Pricing and Proposal" },
    { "id": 13, "key": "Broker to Review", "text": "Trip review" },
    { "id": 14, "key": "-", "text": "Draft" },
    { "id": 15, "key": "-", "text": "Partially Assigned" },
    { "id": 21, "key": "-", "text": "Ongoing Trip" }
]

export const initialState: any = {
  tripFilter: {
    clientName: "",
    displayName: "",
    tripStatus: defaultAppliedStatusArray,
    tripType: "",
    broker: [],
    airports: [],
    type_ids: [],
    aircraft_ids: [],
    operator_ids: [],
    tail_numbers: [],
    fbos: [],
    tripStartDate: new Date(),
    tripEndDate: "",
  },
  dataState: {
    filter: {
      filters: [
            {
                "field": "tripStatus",
                "filters": [
                    { "field": "tripStatusId", "operator": "eq", "value": 1 },
                    { "field": "tripStatusId", "operator": "eq", "value": 2 },
                    { "field": "tripStatusId", "operator": "eq", "value": 3 },
                    { "field": "tripStatusId", "operator": "eq", "value": 4 },
                    { "field": "tripStatusId", "operator": "eq", "value": 5 },
                    { "field": "tripStatusId", "operator": "eq", "value": 7 },
                    { "field": "tripStatusId", "operator": "eq", "value": 8 },
                    { "field": "tripStatusId", "operator": "eq", "value": 12 },
                    { "field": "tripStatusId", "operator": "eq", "value": 13 },
                    { "field": "tripStatusId", "operator": "eq", "value": 14 },
                    { "field": "tripStatusId", "operator": "eq", "value": 15 },
                    { "field": "tripStatusId", "operator": "eq", "value": 21 },
                    { "field": "tripStatusId", "operator": "eq", "value": 17 },
                    { "field": "tripStatusId", "operator": "eq", "value": 18 },
                    { "field": "tripStatusId", "operator": "eq", "value": 19 },
                    { "field": "tripStatusId", "operator": "eq", "value": 20 },
                    { "field": "tripStatusId", "operator": "eq", "value": 16 }
                ],
                "logic": "or"
            },
            {
                "field": "tripEndDate",
                "operator": "gte",
                "value": moment().format("YYYY-MM-DD") + "T00:00:00.000Z"
            }
        ],
        logic: "and",
    },
    sort: [
      { field: "tripEndDate", dir: "asc" },
      { field: "tripStartDate", dir: "asc" },
    ],
    take: DEFAULT_PAGE_SIZE,
    skip: 0,
  },
  tripFrom: {
    isLoading: false,
    selectedStep: null,
    isEditAfterSent: false,
    overview: null,
    formStepList: [],
    statusObj: {
      draftSubmitCount: 0,
      isDirty: false,
    },
    duplicateTrip: false,
  },
  tripDetails: {
    isLoading: false,
    overview: null,
    statusObj: {
      draftSubmitCount: 0,
      isDirty: false,
    },
  },
};

const TripReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.TRIP_FILTER_CHANGE_HANDLER:
      return {
        ...state,
        tripFilter: { ...action.payload },
      };
    case type.TRIP_FILTER_LOADED:
      return {
        ...state,
        dataState: action.payload,
    };
    case type.UPDATE_TRIP_IS_LOADING: {
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                isLoading: action.payload,
            }
        };
    }
    case type.UPDATE_TRIP_LOADING: {
        return {
            ...state,
            tripDetails: {
                ...state.tripDetails,
                isLoading: action.payload,
            }
        };
    }
    case type.UPDATE_TRIP_OVERVIEW: {
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                overview: action.payload,
            }
        };
    }
    case type.UPDATE_TRIP_FORM_STEPS:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                formStepList: action.payload
            }
        };
    case type.UPDATE_SELECTED_STEP:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                selectedStep: action.payload
            }
        };
    case type.UPDATE_IS_EDIT_AFTER_SENT:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                isEditAfterSent: action.payload
            }
        };
    case type.UPDATE_TRIP_FORM_STATUS_OBJ_DRAFT:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                statusObj: {
                    ...state.tripFrom?.statusObj,
                    draftSubmitCount: action.payload
                }
            }
        };
    case type.UPDATE_TRIP_STATUS_OBJ_DRAFT:
        return {
            ...state,
            tripDetails: {
                ...state.tripDetails,
                statusObj: {
                    ...state.tripDetails?.statusObj,
                    draftSubmitCount: action.payload
                }
            }
        };
    case type.UPDATE_TRIP_FORM_STATUS_OBJ_DIRTY:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                statusObj: {
                    ...state.tripFrom.statusObj,
                    isDirty: action.payload
                }
            }
        };
    case type.UPDATE_TRIP_FORM_DUPLICATE_TRIP:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                duplicateTrip: action.payload
            }
        };
    case type.UPDATE_TRIP_STATUS_OBJ_DIRTY:
        return {
            ...state,
            tripDetails: {
                ...state.tripDetails,
                statusObj: {
                    ...state.tripDetails.statusObj,
                    isDirty: action.payload
                }
            }
        };
    case type.UPDATE_TRIP_DETAIL:{
        return {
            ...state,
            tripDetails: {
                ...state.tripDetails,
                overview: action.payload
            }
        }
    }
    case type.RESET_TRIP: {
        return {
            ...state,
            tripFrom: {
                isLoading: false,
                duplicateTrip: false,
                selectedStep: null,
                overview: null,
                formStepList: [],
                statusObj: {
                    draftSubmitCount: 0,
                    isDirty: false,
                }
            },
        };
    }
    case type.RESET_TRIP_DETAIL: {
        return {
            ...state,
            tripDetails: initialState.tripDetails
        };
    }
    default:
      return state;
  }
};

export default TripReducer;

import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {isEmpty, find} from "lodash";
import { getAllPermission } from "../../redux/action/user";
import { CONFIGURATION_PERMISSION, GLOBAL_PERMISSIONS } from "../../constant/permissions";
import Tabs from "../../components/atoms/tab/tab";
import "./index.scss";

export enum SYSTEM_SETTINGS_TYPE {
  ROLE = "role",
  TEAM = "team",
  USER = "user",
  ACCOUNT = "account",
  CONFIGURATION = "configuration",
};

interface ITab {
  title: string,
  key: SYSTEM_SETTINGS_TYPE,
  navigateUrl: string
}

const URTManagement: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { permission } = useSelector((state: any) => state.auth);
  const { loader } = useSelector((state: any) => state.loader);
  const [tabList, setTabList] = React.useState<Array<ITab>>([]);
  const [selectedTab, setSelectedTab] = React.useState<string | null>(null);

  React.useEffect(() => {
    let rowData: Array<ITab> = [];
    const roleGlobalPermission = permission?.permissions?.[0] || [];
    if (roleGlobalPermission.includes(GLOBAL_PERMISSIONS.VIEW_ROLE_MANAGEMENT)) {
      rowData.push({title: "Role Management", key: SYSTEM_SETTINGS_TYPE.ROLE, navigateUrl: "/system-setting/role-management"});
    }
    if (roleGlobalPermission.includes(GLOBAL_PERMISSIONS.VIEW_TEAM_MANAGEMENT)) {
      rowData.push({title: "Team Management", key: SYSTEM_SETTINGS_TYPE.TEAM, navigateUrl: "/system-setting/team-management"})
    }
    if (roleGlobalPermission.includes(GLOBAL_PERMISSIONS.VIEW_USER_MANAGEMENT)) {
      rowData.push({title: "User Management", key: SYSTEM_SETTINGS_TYPE.USER, navigateUrl: "/system-setting/user-management"})
    }
    rowData = [
      ...rowData,
      {title: "Account Management", key: SYSTEM_SETTINGS_TYPE.ACCOUNT, navigateUrl: "/system-setting/account-management"},
    ];
    if(roleGlobalPermission.includes(CONFIGURATION_PERMISSION.AIRCRAFTRATE) || roleGlobalPermission.includes(CONFIGURATION_PERMISSION.BROKERMGMT)) {
      rowData.push({title: "Configuration Management", key: SYSTEM_SETTINGS_TYPE.CONFIGURATION, navigateUrl: "/system-setting/configuration-management"})
    }
    
    rowData = rowData.filter(item => {
      if (permission?.menu?.filter((x: any) => x.module == "SYSTEMSETTING")[0].subModule?.includes(item.title.replace(/\s/g, "").toUpperCase()) || item.key === SYSTEM_SETTINGS_TYPE.CONFIGURATION) {
        return item;
      }
    });
    setTabList(rowData);
    dispatch(getAllPermission());
  }, []);

  React.useEffect(() => {
    if (!isEmpty(tabList) && location.pathname) {
      const selectedTab = find(tabList, {navigateUrl: location.pathname});
      setSelectedTab(selectedTab ? selectedTab.key : tabList[0].key);
    }
  }, [tabList, location]);

  return (
    <div className="userDetailsWrapper" data-testid="urt-page-id">
      <div className="container-fluid">
        <Tabs isLink activeKey={selectedTab} dataTestId="tab-id" tabList={tabList}>
          {loader ? <React.Fragment>Loading</React.Fragment> : selectedTab && (
            <Outlet />
          )}
        </Tabs>
      </div>
    </div>
  );
}

export default React.memo(URTManagement);
import { lazy } from 'react';

export const customeRoutes = {
     HomeScreen: lazy(() => import('../pages/home')),
     DashboardScreen: lazy(() => import('../pages/dashboard')),
     SaleDashboard: lazy(() => import('../pages/dashboard/SaleDashboard')),
     FinanceDashboard: lazy(() => import('../pages/dashboard/FinanceDashboard')),
     LeadershipDashboard: lazy(() => import('../pages/dashboard/LeadershipDashboard')),
     LeaderboardDashboard: lazy(() => import('../pages/dashboard/LeaderboardDashboard')),
     FormScreen : lazy(() => import('../pages/Form/FormValidation')),
     TeamScreen : lazy(() => import('../pages/URTManagement/team/team-screen')),

     URTManagement: lazy(() => import('../pages/URTManagement')),
     RoleManagement: lazy(() => import('../pages/URTManagement/RoleManagement')),
     TeamManagement: lazy(() => import('../pages/URTManagement/TeamManagement')),
     UserManagement: lazy(() => import('../pages/URTManagement/UserManagement')),
     AccountManagement: lazy(() => import('../pages/URTManagement/AccountManagement')),
     ConfigurationManagement: lazy(() => import('../pages/URTManagement/configuration/ConfigurationManagement')),

     FinanceTripAdministrationList: lazy(() => import('../pages/finance/trip/FinanceTripAdministrationList')),
     
     //  Contact
     ContactDetails: lazy(() => import('../pages/contact/contactDetails')),
     ContactDetailsDetailsTab: lazy(() => import('../pages/contact/contactDetails/details')),
     ContactDetailsTripsTab: lazy(() => import('../pages/contact/contactDetails/trips')),
     ContactDetailsJetCardsTab: lazy(() => import('../pages/contact/contactDetails/jetCards')),
     ContactDetailsPassengersTab: lazy(() => import('../pages/contact/contactDetails/passengers')),
     ContactDetailsTasksTab: lazy(() => import('../pages/contact/contactDetails/tasks')),
     ContactDetailsNotesTab: lazy(() => import('../pages/contact/contactDetails/notes')),
     ContactDetailsWebInquiriesTab: lazy(() => import('../pages/contact/contactDetails/webInquiries')),

     //  Trips 
     TripListing: lazy(() => import('../pages/trip')),
     TripDetails: lazy(() => import('../pages/trip/tripDetails')),
     TripDetailsDetailsTab: lazy(() => import('../pages/trip/tripDetails/details')),
     TripDetailsPassengersTab: lazy(() => import('../pages/trip/tripDetails/passengers')),
     TripDetailsFinancesTab: lazy(() => import('../pages/trip/tripDetails/finances')),
     TripDetailsTasksTab: lazy(() => import('../pages/trip/tripDetails/tasks')),
     TripDetailsNotesTab: lazy(() => import('../pages/trip/tripDetails/notes')),
     TripDetailsActivityHistoryTab: lazy(() => import('../pages/trip/tripDetails/activityHistory')),
     CreatTrip: lazy(() => import('../pages/trip/manageTrip/createTrip')),
     TripProposalPreviewBroker: lazy(() => import('../pages/trip/manageTrip/proposalPreview/broker/proposal')),
     TripProposalPreviewClient: lazy(() => import('../pages/trip/manageTrip/proposalPreview/client/proposal')),
     TripProposalPreviewClientRecipient: lazy(() => import('../pages/trip/manageTrip/proposalPreview/client/proposal/ClientReviewRecipient')),
     TripProposalClientPreliminaryInvoice: lazy(() => import('../pages/trip/manageTrip/proposalPreview/client/invoice/ClientPreliminaryInvoice')),
     TripProposalClientInvoice: lazy(() => import('../pages/trip/manageTrip/proposalPreview/client/invoice/ClientInvoice')),
     TripFeedbackClient: lazy(() => import('../pages/trip/manageTrip/proposalPreview/client/feedback')),
     TripFeedbackBroker: lazy(() => import('../pages/trip/manageTrip/proposalPreview/broker/feedback')),
     ManageTrip: lazy(() => import('../pages/trip/manageTrip')),
     
     //  Finance
     Finance: lazy(() => import('../pages/finance')),
     FinanceOperatorPayments: lazy(() => import('../pages/finance/operatorPayments')),

     //  Task
     Tasks: lazy(() => import('../pages/tasks')),

     JetCard: lazy(() => import('../pages/jet-card/jet-card')),
     CreateTeamScreen: lazy(() => import('../pages/URTManagement/team/CreateTeam/CreateTeam')),
     CreateRoleScreen: lazy(() => import('../pages/URTManagement/create-role/CreateRole')),
     ContactListScreen: lazy(() => import('../pages/contact/contact-list/ContactList')),
     AircraftListScreen: lazy(() => import('../pages/aviation-master-data-management/aircrafts/index')),
     AirportFboListScreen: lazy(() => import('../pages/aviation-master-data-management/aircrafts/AirportFboList')),
     LoginScreen: lazy(() => import('../pages/Login/Login')),
     TeamList: lazy(() => import('../pages/URTManagement/team/TeamList/TeamList')),
     GlobalSearchResults: lazy(() => import('../pages/GlobalSearchResults/index')),

     UserDetails: lazy(() => import('../pages/URTManagement/user/user-details/UserDetails')),
     JetCardList: lazy(() => import('../pages/jet-card/JetCardList')),     
     CreateJetCard: lazy(() => import('../pages/create-jet-card/create-jetcard')),     
     JetCardPromotionsList: lazy(() => import('../pages/jet-card/JetCardPromotionsList')),
     JetCardAgreements: lazy(() => import('../pages/jet-card/JetCardAgreements')),
     Initiation: lazy(() => import('../pages/JetCardAgreementExternal/Initiation')),
     Thankyou: lazy(() => import('../pages/JetCardAgreementExternal/Thankyou')),

     Notifications: lazy(() => import('../pages/Notification/NotificationList')),
     PassengersList: lazy(() => import('../pages/passanger/PassengersList')),
     
     UnAuthorized: lazy(() => import('../pages/unAuthorized/UnAuthorizedScreen')),     
}

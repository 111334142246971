import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TextArea } from "@progress/kendo-react-inputs";
import Scrollbars from "react-custom-scrollbars";
import { FloatingLabel } from "@progress/kendo-react-labels";
import React from "react";
import { ICancelTrip, cancelTrip } from "../../../../service/trip";
import { Error } from "@progress/kendo-react-labels";
import { ToastType } from "../../../../components/common/ToastNotification";
import { setToastNotification } from "../../../../redux/action/ToastNotificationAction";
import { useDispatch } from "react-redux";
import Loader from "../../../../components/common/Loader";
import { TRIP_STATUS_MAP_LIST } from "../../../../constant/constant";
import { TRIP_STATUS_KEY } from "../../../../constant/enum";
import WarningImg from "../../../../Assets/Images/warning-yellow.svg";

enum TripCancelType {
  CANCEL_WITH_COST = "CANCEL_WITH_COST",
  CANCEL_WITHOUT_COST = "CANCEL_WITHOUT_COST"
}

export default function CancelTrip({ toggleCancelConfirmationDialog, trip, onSuccess }: any) {
  const dispatch = useDispatch();
  const [tripCancelData, setTripCancelData] = React.useState<ICancelTrip>({
    cancellationReason: "",
    cancelWithCost: false,
    tripId: trip.id
  })
  const [reasonError, setReasonError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const showToast = (message: string, type: ToastType) => {
    dispatch(setToastNotification({ type, message }));
  };
  
  const OnTripCancelClickHandler = (cancelType: TripCancelType) => {
    setLoading(true);
    if(!Boolean(tripCancelData.cancellationReason)) {
      setReasonError("This field is required.");
      setLoading(false);
      return;
    } else {
      setReasonError("");
      cancelTrip({
        cancellationReason: tripCancelData.cancellationReason,
        tripId: tripCancelData.tripId,
        cancelWithCost: cancelType === TripCancelType.CANCEL_WITH_COST,
      }, (response, isError) => {
        setLoading(false);
        if(isError) {
          showToast("Something went wrong. Try again later.", "error")
        } else {
          onSuccess();
          showToast("Trip cancelled successfully", "success");
        }
       
      })
    }
  }

  const showCancelWithCost = [
    TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.ACCEPTED].id, 
    TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.ASSIGNMENT_MADE].id,
    TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.BROKER_TO_REVIEW].id,
    TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.ONGOING_TRIP].id 
  ].includes(trip?.status?.id || trip?.tripStatusId)

  return (
    <Dialog
      title={
        <div className="modelCustomTitle">
          <h4>Cancel Trip</h4>
        </div>
      }
      onClose={toggleCancelConfirmationDialog}
      className="addContactDetailsModel"
    >
       {loading ? <Loader /> : <></>}
      <Scrollbars className="contactDetailsInner" autoHide>
        <div className="contactDetailsGroup">
          <div className="maverickForm">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FloatingLabel
                    label={"Reason for Cancellation"}
                    editorId={"Notes"}
                    className={Boolean(tripCancelData.cancellationReason) ? 'k-focus' : ''}
                  >
                    <TextArea
                      className="customInput"
                      id={"Notes"}
                      onChange={(e) => setTripCancelData((data) => ({
                        ...data,
                        cancellationReason: e.value
                      }))}
                      value={tripCancelData.cancellationReason}
                      maxLength={2000}
                    />
                  </FloatingLabel>
                  {reasonError && <Error>{reasonError}</Error>}
                </div>
              </div>
            </div>
          </div>
          <div className="alert alert-warning bottom-0 lower-warning position-absolute w-100">
            <img src={WarningImg} alt="WarningImg" />
            Please notify scheduling team before proceeding with cancellation.
          </div>
        </div>
      </Scrollbars>
      <div className="modelFooterAction">
        <Button
          className="closeContinueBtn"
          fillMode="flat"
          themeColor="primary"
          onClick={toggleCancelConfirmationDialog}
        >
          Close & Continue Trip
        </Button>
        <Button fillMode="outline" themeColor="primary" onClick={() => OnTripCancelClickHandler(TripCancelType.CANCEL_WITHOUT_COST)}>
          Cancel without cost
        </Button>
        {showCancelWithCost && (<Button themeColor="primary" onClick={() => OnTripCancelClickHandler(TripCancelType.CANCEL_WITH_COST)}>Cancel with cost</Button>)}
      </div>
    </Dialog>
  );
}



import AxiosService, { API_STATUS_CODE } from "./Service";
import { URLS, buildAPIURL } from "../utils/url";
import { ERROR_MESSAGE, NOTIFICATION_TYPE, setToastNotification } from "../redux/action/ToastNotificationAction";
import { LOCALSTORAGE_KEY, setLocalStorageByKey, getLocalStorageByKey, isUserAuthenticated, getMVToken, customAPI, setMVToken, setMVADToken, setMVRefresh } from "../utils/auth";
import { setHeaderPermission, setLoginUser, setUserAzurAD } from "../redux/action/auth";
import { getAllUsers } from "../redux/action/user";

export const fetchDataOnInitialLogin = (dispatch: Function) => {
    dispatch(getAllUsers());
}
interface IAuthPayload {
    code: string;
    origin?: string;
}
export const fetchToken = async (
    code: string,
    dispatch: Function,
    callback: (_: null, isError: boolean) => void
) => {
    const payload: IAuthPayload = {
        code
    };
    if (process.env.REACT_APP_ORIGIN_URL) {
        payload.origin = process.env.REACT_APP_ORIGIN_URL;
    }
    const result = await AxiosService({
        method: "post",
        url: buildAPIURL(URLS.FetchToken),
        body: JSON.stringify(payload),
        headers: JSON.stringify({
            headers: {
                isPublic: true
            }
        })
    });
    if (result.response) {
        if (result.response.responseCode === API_STATUS_CODE.SUCCESS) {
            const { responseData } = result.response;
            const loggedInUser = responseData?.userData?.contact?.[0] || {};
            loggedInUser.id = responseData?.userData?.user?.[0].id;
            setMVToken(responseData?.access_token);
            setMVADToken(responseData?.ad_access_token);
            setMVRefresh(responseData?.refresh_token);
            setLocalStorageByKey(LOCALSTORAGE_KEY.TOKEN, responseData.access_token);
            setLocalStorageByKey(LOCALSTORAGE_KEY.REFRESH_TOKEN, responseData.refresh_token);
            dispatch(setHeaderPermission(responseData?.userData || {}));
            dispatch(setLoginUser(loggedInUser));
            dispatch(setUserAzurAD(responseData?.userData?.userAzurAD?.[0] || {}));
            fetchDataOnInitialLogin(dispatch);
            callback(null, false);
        } else if (result.response.responseCode === API_STATUS_CODE.NOT_ACCEPTABLE) {
            dispatch(setToastNotification({ type: NOTIFICATION_TYPE.ERROR, message: result.response.responseMessage }));
            callback(null, true);
        }
    } else {
        dispatch(setToastNotification({ type: NOTIFICATION_TYPE.ERROR, message: ERROR_MESSAGE }));
        callback(null, true);
    }
};

export const fetchRefreshToken = async (
    callback: (_: null, isError: boolean) => void
) => {
    const result = await AxiosService({
        method: "post",
        url: buildAPIURL(URLS.FetchRefreshToken),
        body: JSON.stringify({
            code: getLocalStorageByKey(LOCALSTORAGE_KEY.REFRESH_TOKEN)
        }),
        headers: JSON.stringify({
            headers: {
                isPublic: true
            }
        })
    });
    if (result.response && result.response.responseCode === API_STATUS_CODE.SUCCESS) {
        setLocalStorageByKey(LOCALSTORAGE_KEY.TOKEN, result.response.responseData);
        callback(null, false);
    } else {
        callback(null, true);
    }
};

export const fetchTokenSSO = async (
    dispatch: Function,
    callback: (_: null, isError: boolean) => void
) => {
    if (process.env.REACT_APP_SSO_ENABLED === "true") {
        const isUserAuth:any = isUserAuthenticated();
        if (isUserAuth ==null || isUserAuth === '') {
            const token:any = getMVToken();
            if(token != null && token !== ''){
                const config:any = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                };
                const payload:any = {};
                if (process.env.REACT_APP_ORIGIN_URL) {
                    payload.origin = process.env.REACT_APP_ORIGIN_URL;
                }
                const result = await customAPI("post",`${process.env.REACT_APP_API_BASE_URL}${buildAPIURL(URLS.FetchTokenSSO)}`,payload,config);

                if (result.responseCode === 200) {
                    if (result.responseCode === API_STATUS_CODE.SUCCESS) {
                        const { responseData } = result;
                        const loggedInUser = responseData?.userData?.contact?.[0] || {};
                        loggedInUser.id = responseData?.userData?.user?.[0].id;
                        setLocalStorageByKey(LOCALSTORAGE_KEY.TOKEN, responseData.access_token);
                        setLocalStorageByKey(LOCALSTORAGE_KEY.REFRESH_TOKEN, responseData.refresh_token);
                        dispatch(setHeaderPermission(responseData?.userData || {}));
                        dispatch(setLoginUser(loggedInUser));
                        dispatch(setUserAzurAD(responseData?.userData?.userAzurAD?.[0] || {}));
                        fetchDataOnInitialLogin(dispatch);
                        callback(null, false);
                    } else if (result.responseCode === API_STATUS_CODE.NOT_ACCEPTABLE) {
                        callback(null, true);
                    }
                } else {
                    callback(null, true);
                }
            } else{
                callback(null, true);
            }
        }
    }else{
        callback(null, true);
    }

};

export const postError = async (errorStackJSONString: string) => {
    await AxiosService({
        method: "post",
        url: buildAPIURL(URLS.PostFrontendError),
        body: JSON.stringify(errorStackJSONString),
    });
};

import { IActionButtons } from "../components/atoms/confirmation-dialog/CustomDialog";
import { AIRCRAFT_TYPE_KEY, JETCARD_TYPE_KEY, TRIP_TYPE_KEY } from "./enum";

export const IDLE_TIMER_TIMEOUT = 60 * 60;
// font family
export const FONT_WEIGHT = {
  BOLD: "bold",
  MEDIUM: "medium",
  REGULAR: "regular",
  LIGHT: "light",
};

// Text Align
export const TEXT_ALIGN = {
  CENTER: "center",
  LEFT: "left",
  RIGHT: "right",
};

// InputText status
export const IS_FOCUS = "IS_FOCUS";
export const IS_SUCCESS = "IS_SUCCESS";
export const IS_ERROR = "IS_ERROR";
export const IS_EDITABLE = "IS_EDITABLE";
export const IS_BLUR = "IS_BLUR";
export const IS_GRAYED_OUT = "IS_GRAYED_OUT";

export const rowData = [
  {
    id: "1",
    name: "One",
  },
  {
    id: "2",
    name: "Two",
  },
  {
    id: "3",
    name: "Three",
  },
  {
    id: "4",
    name: "Four",
  },
];

export const userConstants = {
  LOGIN_RESPONSE: "LOGIN_RESPONSE",
  SET_READ_ONLY_POP_UP_FLAG: "SET_READ_ONLY_POP_UP_FLAG",
};

export const allContactStatusView: any = {
  NewContact: "New Contact",
  PotentialClient: "Potential Client",
  Initiated: "Initiated",
  Client: "Client",
  Reject: "Reject",
  Qualified: "Potential Client",
  NotInterested: "Reject",
  NoAnswer: "Reject",
};

export const permisssionData = {
  aclModules: [
    {
      aclModuleId: 1,
      aclModuleName: "System Settings",
      aclModuleCode: "SYSTEMSETTING",
      aclSubmodules: [
        {
          aclsubmoduleId: 1,
          aclsubModuleName: "User Management",
          aclsubmoduleCode: "USERMANAGEMENT",
          aclResource: [
            {
              aclResourceId: 10006,
              aclResourceName: "View User",
              aclResourceCode: "VIEWUSER",
            },
            {
              aclResourceId: 10013,
              aclResourceName: "Edit User",
              aclResourceCode: "EditUSER",
            },
          ],
        },
        {
          aclsubmoduleId: 3,
          aclsubModuleName: "Role Management",
          aclsubmoduleCode: "RoleMANAGEMENT",
          aclResource: [
            {
              aclResourceId: 10007,
              aclResourceName: "View Role",
              aclResourceCode: "VIEWRole",
            },
            {
              aclResourceId: 10016,
              aclResourceName: "Edit Role",
              aclResourceCode: "EDITROLE",
            },
          ],
        },
      ],
    },
    {
      aclModuleId: 1,
      aclModuleName: "Contact",
      aclModuleCode: "CONTACT",
      aclSubmodules: [
        {
          aclsubmoduleId: 4,
          aclsubModuleName: "Contact",
          aclsubmoduleCode: "CONTACT",
          aclResource: [
            {
              aclResourceId: 10009,
              aclResourceName: "View Contact",
              aclResourceCode: "VIEWCONTACT",
            },
            {
              aclResourceId: 10019,
              aclResourceName: "Edit Contact",
              aclResourceCode: "EditCONTACT",
            },
          ],
        },
        {
          aclsubmoduleId: 5,
          aclsubModuleName: "Jetcard",
          aclsubmoduleCode: "JETCARD",
          aclResource: [
            {
              aclResourceId: 10008,
              aclResourceName: "View Card",
              aclResourceCode: "VIEWCARD",
            },
            {
              aclResourceId: 10020,
              aclResourceName: "Edit Card",
              aclResourceCode: "EDITCARD",
            },
          ],
        },
      ],
    },
  ],
};

export const userData = [
  {
    userId: 1,
    email: "admin@jaroop.com",
    created: null,
    active: false,
    firstName: "System",
    lastName: "Administrator",
    teams: "DEFAULT TEAM, Delhi Team, London Team, New York-John Team",
    roles: "Broker, Default Role, Trip Creator",
    permission: null,
    userName: "System Administrator",
    team: null,
  },
];

export const passangertsList = [
  {
    expanded: false,
    id: 262,
    weight: "abc",
    clientId: 57,
    country: {
      id: 2,
      currencyCode: "ALL",
      displayName: "Albania",
      countryCode: "ALB ",
      alphaTwoCode: "AL",
    },
    gender: {
      id: 1,
      displayName: "Male",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 105,
    weight: "",
    clientId: 57,
    country: null,
    gender: null,
    passengerDetail: {
      id: 105,
      passengerId: 105,
      contactTypeId: 1,
      departmentId: 1,
      firstName: "Tam",
      middleName: "",
      lastName: "Dao",
      email: "tdao@jaroop.com",
      title: "",
      companyId: null,
      image: null,
      website: "",
      dateOfBirth: "abc",
      notes: "",
    },
    address: {
      id: 105,
      passengerContactsId: 105,
      address: "",
      address2: "",
      city: "",
      stateId: null,
      countryId: null,
      zip: "",
      phone: "4055460989",
      phoneExt: "",
      fax: "",
      cell: "",
    },
    identityDoc: [
      {
        id: 34,
        passengerId: 105,
        documentTypes: "DrivingLisence",
        otherTypeName: "string",
        docContent: "string",
        file: {
          id: 26,
          passengerId: 105,
          path: "string",
          name: "string",
          hash: "string",
          mimeType: "string",
          deleted: false,
        },
        docNumber: null,
        issueDate: null,
        expirationDate: null,
        active: false,
        deleted: false,
        status: false,
      },
    ],
    pets: [
      {
        id: 3,
        passengerId: 105,
        name: "string",
        petType: "dog",
        weight: "44",
      },
    ],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
  {
    expanded: false,
    id: 267,
    weight: "40",
    clientId: 57,
    country: {
      id: 1,
      currencyCode: "AFN",
      displayName: "Afghanistan",
      countryCode: "AFG ",
      alphaTwoCode: "AF",
    },
    gender: {
      id: 2,
      displayName: "Female",
    },
    passengerDetail: null,
    address: null,
    identityDoc: [],
    pets: [],
  },
];

export const sourceData=[
  { sourceId: 1, source: "ClientReferral",name:'Client Referral' },
  { sourceId: 11, source: "Offline" ,
name:'Offline'},
  { sourceId: 11, source: "Website" ,name:'Website'},

]

export const genderData=["Male", "Female", "Prefer not to Say"]
export const documentTypeData=["Driving Lisence", "Passport", "Other"]
export const usCountryCode=233

export const KnockNotificationConfig = {
  apiKey: "pk_test_zjiOWB-qBkwQqsXFvEu8gIgrWRNc_wQBROARbAl-kOM",
  feedId: "inapptest"
}

export const TRIP_TYPE = [
    {id: 1, key: TRIP_TYPE_KEY.CHARTER, displayName: "Charter"},
    {id: 2, key: TRIP_TYPE_KEY.JET_CARD, displayName: "Jet Card"}
];

export const JETCARD_TYPE = [
    {id: 3, key: JETCARD_TYPE_KEY.All_ACCESS, displayName: "All Access"},
    {id: 2, key: JETCARD_TYPE_KEY.All_IN, displayName: "All IN"},
];

export const AIRCRAFT_TYPE_LIST = [
  {id: 4, key: AIRCRAFT_TYPE_KEY.LIGHT, label: "Light", type: [TRIP_TYPE_KEY.JET_CARD, TRIP_TYPE_KEY.CHARTER]},
  {id: 5, key: AIRCRAFT_TYPE_KEY.MID, label: "Mid", type: [TRIP_TYPE_KEY.JET_CARD, TRIP_TYPE_KEY.CHARTER]},
  {id: 6, key: AIRCRAFT_TYPE_KEY.SUPER_MID, label: "Super-Mid", type: [TRIP_TYPE_KEY.JET_CARD, TRIP_TYPE_KEY.CHARTER]},
  {id: 7, key: AIRCRAFT_TYPE_KEY.HEAVY, label: "Heavy", type: [TRIP_TYPE_KEY.JET_CARD, TRIP_TYPE_KEY.CHARTER]},
  {id: 2, key: AIRCRAFT_TYPE_KEY.TURBO_PROP, label: "Turbo Prop", type: [TRIP_TYPE_KEY.CHARTER]},
  {id: 1, key: AIRCRAFT_TYPE_KEY.PISTON, label: "Piston", type: [TRIP_TYPE_KEY.CHARTER]},
  {id: 3, key: AIRCRAFT_TYPE_KEY.VLJ, label: "VLJ", type: [TRIP_TYPE_KEY.CHARTER]},
  {id: 8, key: AIRCRAFT_TYPE_KEY.OTHER, label: "Other", type: [TRIP_TYPE_KEY.CHARTER]}
]

export const TRIP_STATUS_MAP_LIST = [
    {
        id: 0,
        display_name: "Unknown",
        description: null,
        display_name_new: "Unknown",
        class: "Unknown"
    },
    {
        id: 1,
        display_name: "Quote Open",
        description: null,
        display_name_new: "Scheduling",
        class: "SchedulingPricingProposal"
    }, {
        id: 2,
        display_name: "Quote to Broker",
        description: null,
        display_name_new: "Quote Review",
        class: "QuoteReview"
    }, {
        id: 3,
        display_name: "Sent to Client",
        description: null,
        display_name_new: "Client Review",
        class: "ClientReview"
    }, {
        id: 4,
        display_name: "Accepted",
        description: null,
        display_name_new: "Accepted",
        class: "Accepted"
    }, {
        id: 5,
        display_name: "Assignment Made",
        description: null,
        display_name_new: "Assignment Made",
        class: "AssignmentMade"
    }, {
        id: 6,
        display_name: "Unknown",
        description: null,
        display_name_new: "Unknown",
        class: "Unknown"
    }, {
        id: 7,
        display_name: "Completed",
        description: null,
        display_name_new: "Finance Review",
        class: "FinanceReview"
    }, {
        id: 8,
        display_name: "Finalized",
        description: null,
        display_name_new: "Finalized",
        class: "Finalized"
    }, {
        id: 9,
        display_name: "Cancelled",
        description: null,
        display_name_new: "Cancelled",
        class: "Cancelled"
    }, {
        id: 10,
        display_name: "Cancelled with Cost",
        description: "This is the non-finalized Cancelled With Cost status",
        display_name_new: "Cancelled",
        class: "Cancelled"
    }, {
        id: 11,
        display_name: "Cancelled and Finalized",
        description: "This is the finalized Cancelled With Cost status",
        display_name_new: "Cancelled",
        class: "Cancelled"
    }, {
        id: 12,
        display_name: "Jet Card Pending",
        description: null,
        display_name_new: "Pricing and Proposal",
        class: "SchedulingPricingProposal"
    }, {
        id: 13,
        display_name: "Broker to Review",
        description: "Indicates to the broker that they have not added in all trip fees or are currently in the process of finalizing that trip.",
        display_name_new: "Trip Review",
        class: "TripReview"
    }, {
        id: 14,
        display_name: "Draft",
        description: "Indicates to the broker that they have not added in all trip fees or are currently in the process of finalizing that trip.",
        display_name_new: "Draft",
        class: "Draft"
    }, {
        id: 15,
        display_name: "Partially Assigned",
        description: "Partially Assigned :  Not all legs have Operator assigned. ",
        display_name_new: "Partially Assigned",
        class: "PartiallyAssigned"
    }, { // Not in use
        id: 16,
        display_name: "Broker to Review",
        description: "Indicates to the broker that they have not added in all trip fees or are currently in the process of finalizing that trip.",
        display_name_new: "Trip Review",
        class: "TripReview"
    }, {
        id: 17,
        display_name: "Quote to Broker",
        description: "Substatus of: 2 - Quote to Broker. Indicates trip at Broker and Broker added options on the trip",
        display_name_new: "Quote Review",
        class: "QuoteReview"
    }, {
        id: 18,
        display_name: "Quote to Broker",
        description: null,
        display_name_new: "Quote Review",
        class: "QuoteReview"
    }, {
        id: 19,
        display_name: "Jet Card Pending",
        description: "Substatus of: 12 - Jet Card Pending. Indicates trip at Jet Card Pending and Broker saved proposal",
        display_name_new: "Pricing and Proposal",
        class: "Scheduling"
    }, { // Not in use
        id: 20,
        display_name: "Jet Card Pending",
        description: "Substatus of: 12 - Jet Card Pending. Indicates trip at Jet Card Pending and Broker saved Contract",
        display_name_new: "Pricing and Proposal",
        class: "Scheduling"
    }, {
        id: 21,
        display_name: "Ongoing Trip",
        description: "",
        display_name_new: "Ongoing Trip",
        class: "OngoingTrip"
    }
];

export const TRIP_LEG_STATUS_MAP_LIST = [
	{
		id: 1,
		display_name: "In Queue",
	},
	{
		id: 2,
		display_name: "Quote Review",
	},
	{
		id: 3,
		display_name: "Client Review",
	},
	{
		id: 4,
		display_name: "Accepted",
	},
	{
		id: 5,
		display_name: "Scheduler Assigned",
	},
	{
		id: 6,
		display_name: "Draft",
	},
    {
        id: 7,
        display_name: "Operator Assigned",
    },
	{
		id: 8,
		display_name: "In Transit",
	},
	{
		id: 9,
		display_name: "Completed",
	},
];

// Trip LegStatus
export const tripLegDotStatus:any = {
  "Draft" : 'draft',
  "In Queue" : 'inQueue',
  "Scheduler Assigned" : 'schedulerAssigned',
  "Quote Review" : 'quoteReview',
  "Client Review" : 'clientReview',
  "Accepted" : 'accepted',
  "Operator Assigned" : 'operatorAssigned',
  "In Transit" : 'inTransit',
  "Completed" : 'completed',
  "Cancelled" : 'cancelled',
};

export const RESTART_DIALOG_MESSAGE = "Do you want to restart the process?"
export const RESTART_DIALOG_ACTIONS: IActionButtons = {
  acceptButton: {
    accept: "Yes, Restart the process",
    acceptColor: "error",
  },
  declineButton: {
    decline: "No, Update Here",
    declineColor: "primary",
    fillMode: "outline",
  },
};

export const messageForCVV: string = "You can find your 3-digit security code (CVV) in the signature on the back of your card after the credit card number";


import { DELETE_PASSENGER, GET_ALL_COUNTRY, GET_ALL_PASSENGER_EXPANDED } from './../../constant/redux-const';
import { CREATE_PASSENGER, GET_ALL_PASSENGER } from '../../constant/redux-const';;
const initialState = {
    passengers: [],
    countryList:[],
    stateList:[]
};

const PassengerRducer = (state: any = initialState, action: any) => {

    switch (action.type) {
        /** Load value of ToDo items array */
        case GET_ALL_PASSENGER:
            return {
                ...state,
                passengers: JSON.parse(JSON.stringify(action.payload)),
            };

            case GET_ALL_COUNTRY:
            return {
                ...state,
                countryList: action.payload,
            };
            case GET_ALL_PASSENGER_EXPANDED:

            return {
                ...state,
                passengers: action.payload
            };



        /** Load new ToDo value of ToDo items array */

        case CREATE_PASSENGER:
            return {
                ...state,
                passengers: state.items.concat(action.payload),
            };
        /** Delete particular value of ToDo items array */
        case DELETE_PASSENGER: {
            let deletedArray = state.items.filter((item: any) => item.id !== action.payload);
            return {
                ...state,
                passengers: deletedArray,
            };
        }


        default:
            return state;
    }
};
export default PassengerRducer;

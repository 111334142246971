import React, { useEffect, useCallback } from "react";
import { updateNotification } from "../redux/action/notification";
import { INotification, fetchNotifications } from "../service/notification";
import { useDispatch, useSelector } from "react-redux";
import { useKnockFeed } from "@knocklabs/react-notification-feed";

const NotificationProvider = () => {
    const dispatch = useDispatch();
    const { feedClient } = useKnockFeed();
    const { notifications, totalUnread, primaryNotifications } = useSelector(
      (state: any) => state.notification
    );

    const setNotification = useCallback((notifications: any) => {
      dispatch(
        updateNotification({
          notifications,
        })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setTotalUnread = useCallback((totalUnread: any) => {
      dispatch(
        updateNotification({
          totalUnread,
        })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const onNotificationsReceived = async (resp: any) => {
        try {
          const { items } = resp;
          if (Array.isArray(items)) {
            const notificaionList = [...notifications];
            let notificationTemp: Array<INotification> = [];
            const lastId = Math.max(...notificaionList.map((v) => v.id));
            items.forEach(function (_, index) {
              const latestNotification = JSON.parse(items[index]?.data?.msg_body);
              if (latestNotification.id > lastId && latestNotification?.envioronment === process.env.REACT_APP_KNOCK_ENVIORMENT) {
                const exist =
                  notificaionList.findIndex(
                    (not: any) => not.id === latestNotification.id
                  ) !== -1;
                if (!exist) {
                  notificationTemp.push(latestNotification);
                }
              }
            });
            if (notificationTemp.length > 0) {
              setNotification([...notificationTemp, ...notifications]);
              dispatch(updateNotification({primaryNotifications: [...notificationTemp, ...primaryNotifications]}))
              setTotalUnread(totalUnread + notificationTemp.length);
            }
          }
        } catch (e) {
          console.error("notification : 56 - Exception at", e);
        }
      };

      // Receive all real-time notifications on our feed
      feedClient.on("items.received.realtime", onNotificationsReceived);

      // Cleanup
      return () => {
        feedClient.off("items.received.realtime", onNotificationsReceived);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      feedClient,
      notifications,
      totalUnread,
      primaryNotifications,
    ]);

    useEffect(() => {
      fetchNotifications(20, 0, false, (notifications, totalUnread, totalLength) => {
        dispatch(updateNotification({ notifications, totalUnread, totalLength, primaryNotifications: notifications }));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <React.Fragment></React.Fragment>;
};

export default NotificationProvider;
